<template>
  <!-- 新增仓库页 -->
  <div class="page-info-content">
    <h3>基本信息</h3>
    <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="135px" label-position="left" class="form-item-row">
      <el-form-item
        label="所属企业"
        prop="cmpCode"
        :rules="[
          { required: true, message: '请输入选择', trigger: 'change' }
        ]"
      >
        <el-select
          v-model="formData.cmpCode"
          class="cmpNameSearch"
          filterable
          remote
          reserve-keyword
          :disabled="disabled"
          placeholder="请输入关键字搜索"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in cmpNameArr"
            :key="item.cmpCode"
            :label="item.cmpName"
            :value="item.cmpCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="仓库名称"
        prop="warehouseName"
        :rules="[
          { required: true, message: '请输入', trigger: 'bulr' },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur'
          }
        ]"
      >
        <el-input v-model="formData.warehouseName" :disabled="disabled" placeholder="请输入" />
      </el-form-item>
      <el-form-item
        label="联系人"
        prop="linkMan"
        :rules="[
          { required: true, message: '请输入', trigger: 'bulr' }
        ]"
      >
        <el-input v-model="formData.linkMan" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="联系电话" prop="linkPhone" :rules="[{...formPattern.studioCameraPhone}, { required: true, message: '请输入联系电话', trigger: 'blur' }, { min: 1, max: 25, message: '不得超过25位字符', trigger: 'blur' },]">
        <el-input v-model="formData.linkPhone" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="所在地区" prop="allCity" :rules="[...rules.notEmpty]">
        <el-cascader
          v-model="formData.allCity"
          :props="{ label: 'name', value: 'name', children: 'children' }"
          :options="allCityOption"
          @change="getCity"
        />
      </el-form-item>
      <el-form-item
        label="详细地址"
        prop="placeDetailAddr"
        :rules="[
          { required: true, message: '请输入', trigger: 'bulr' }
        ]"
      >
        <el-input v-model="formData.placeDetailAddr" placeholder="请输入" />
      </el-form-item>
      <el-form-item
        label="支持加工"
        prop="isMachining"
      >
        <el-radio v-model="formData.isMachining" label="01">
          是
        </el-radio>
        <el-radio v-model="formData.isMachining" label="00">
          否
        </el-radio>
      </el-form-item>
      <el-form-item
        label="仓库属性"
        prop="warehouseProp"
      >
        <el-select
          v-model="formData.warehouseProp"
          placeholder="请选择"
        >
          <el-option
            v-for="item in $store.getters.getDictionaryItem('warehouseProp')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="仓库类型"
        prop="warehouseType"
      >
        <el-select
          v-model="formData.warehouseType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in $store.getters.getDictionaryItem('warehouseType')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="仓库等级"
        prop="warehouseLevel"
      >
        <el-select
          v-model="formData.warehouseLevel"
          placeholder="请选择"
        >
          <el-option
            v-for="item in $store.getters.getDictionaryItem('warehouseLevel')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <div class="table-box">
        <h3>库区信息</h3>
        <div class="list-operation">
          <el-button size="small" type="primary" @click="addGoodsAllocation">
            添加库区货位
          </el-button>
        </div>
        <el-form-item class="lastOne" label="" label-width="0px">
          <Table :item-data="itemData" :list-data="formData.warehouseSubsystemInfos">
            <template #delete="{ index }">
              <i class="el-icon-remove-outline" @click="deleteGoodsAllocation(index)" />
            </template>
          </Table>
        </el-form-item>
      </div>
    </el-form>
    <div class="button-combination">
      <el-button
        :loading="$store.state.loading"
        size="small"
        type="primary"
        class="widen-button"
        @click="submitForm('ruleFormRef')"
      >
        确定
      </el-button>
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog
      ref="dialog"
      :edit-form-data="goodsAllocation"
      :form-item="addAndEditFormItem"
      dialog-width="30%"
      column="1"
      class="goods-allocation"
      @getFormData="getFormData"
    >
      <template slot="dynamicInsert">
        <div class="flex-row-checkbox">
          <el-form-item
            v-if="checked"
            label="库区编号"
            prop="goodsYardNo"
            :rules="[{ required: true, message: '请输入库区编号', trigger: 'blur' }, {
              min: 1,
              max: 5,
              message: '长度在 1 到 5 个字符',
              trigger: 'blur'
            }, { ...formPattern.numericLettersCharacter }]"
          >
            <el-input v-model="goodsAllocation.goodsYardNo" placeholder="请输入" />
          </el-form-item>
          <el-form-item
            v-else
            label="关联库区"
            prop="goodsYardNo"
            :rules="[
              { required: true, message: '请选择库区编号', trigger: ['change', 'blur'] }
            ]"
          >
            <el-select
              v-model="goodsAllocation.goodsYardNo"
              placeholder="请选择"
            >
              <el-option
                v-for="cl in goodsAllocationOption"
                :key="cl.goodsYardNo"
                :label="cl.goodsYardNo"
                :value="cl.goodsYardNo"
              />
            </el-select>
          </el-form-item>
          <el-checkbox v-model="checked" @change="changeChecked">
            新建库区
          </el-checkbox>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { rules, formPattern } from '@/unit/matchFormRegex'
import { getCmpBaseinfoList, getSelectAreaTree } from '@/api/common'
import {
  warehouseUpdateAndAdd,
  warehouseGetYarnAndArea
} from '@/api/warehousingSystem'
import Table from '@/components/Table'
import Dialog from '@/components/Dialog2'
export default {
  components: { Table, Dialog },
  data() {
    return {
      formData: {
        warehouseSubsystemInfos: []
      },
      rules,
      formPattern,
      cmpNameArr: [],
      warehouseNameArr: [],
      disabled: false,
      allCityOption: [],
      itemData: [
        { label: '库区编号', prop: 'goodsYardNo', width: 180 },
        { label: '库区描述', prop: 'goodsYardRemark', width: 180 },
        { label: '货位号', prop: 'locationNo', width: 180 },
        { label: '货位描述', prop: 'locationDesc', width: 180 },
        { label: '操作', prop: 'delete', width: 180 }
      ],
      goodsAllocationOption: [],
      goodsAllocation: {
        goodsYardNo: ''
      },
      checked: false,
      addAndEditFormItem: [
        {
          label: '库区描述',
          type: 'input',
          disabled: false,
          value: 'goodsYardRemark',
          rules: [
            { required: false, message: '请输入库区描述', trigger: 'blur' },
            {
              min: 1,
              max: 15,
              message: '长度在 1 到 15 个字符',
              trigger: 'blur'
            }
          ]
        },
        {
          label: '货位号',
          type: 'input',
          disabled: false,
          value: 'locationNo',
          rules: [
            { required: true, message: '请输入货位号', trigger: 'blur' },
            {
              min: 1,
              max: 7,
              message: '长度在 1 到 7 个字符',
              trigger: 'blur'
            },
            { ...formPattern.numericLettersCharacter }
          ]
        },
        {
          label: '货区描述',
          type: 'input',
          disabled: false,
          value: 'locationDesc',
          rules: [
            { required: false, message: '请输入货区描述', trigger: 'blur' },
            {
              min: 1,
              max: 15,
              message: '长度在 1 到 15 个字符',
              trigger: 'blur'
            }
          ]
        }
      ]
    }
  },
  created() {
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
  },
  mounted() {
    this.getDetails()
    this.remoteMethod()
  },
  methods: {
    // 获取详情数据
    getDetails() {
      if (this.$route.query.type && this.$route.query.type === 'edit') {
        this.formData = this.$store.state.resourceByTaskObj || JSON.parse(sessionStorage.resourceByTaskObj)
        this.remoteMethod(this.formData.cmpName)
        this.disabled = true
      }
    },
    getCity(arr) {
      if (arr.length !== 3) return
      this.formData.placeProvince = arr[0]
      this.formData.placeCity = arr[1]
      this.formData.placeTown = arr[2]
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoList(value || '', res => {
        this.cmpNameArr = res.data.filter(item => item.cmpRoleList.includes('09'))
      })
    },
    // 新增货位号弹窗显示
    addGoodsAllocation() {
      this.$refs.dialog.editFormVisible = true
      this.goodsAllocation = { goodsYardNo: '' }
      warehouseGetYarnAndArea(this.formData.id || '', res => {
        let arr = [...res.data.wmsYardRespList || []].concat(this.formData.warehouseSubsystemInfos)
        const obj = {}
        arr = arr.reduce((total, next) => {
          obj[next.goodsYardNo] ? '' : obj[next.goodsYardNo] = true && total.push(next)
          return total
        }, [])
        this.goodsAllocationOption = arr
      })
    },
    // 库区编号切换
    changeChecked(val) {
      this.goodsAllocation = { goodsYardNo: '' }
      this.addAndEditFormItem[1].disabled = false
    },
    // 移除货位号
    deleteGoodsAllocation(index) {
      this.$confirm('移除后，该条数据被删除', '移除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.formData.warehouseSubsystemInfos.splice(index, 1)
        })
        .catch(() => {})
    },
    // 选择关联库区
    // getAllocation(val) {
    //   if (!val) return
    //   this.goodsAllocationOption.forEach((item) => {
    //     if (item.goodsYardNo === val) {
    //       this.addAndEditFormItem.map((cl) => {
    //         if (cl.value === 'locationNo') {
    //           cl.child = item.wmsAreaRespList
    //         }
    //         return cl
    //       })
    //     }
    //   })
    // },
    // 新增货位号
    getFormData(value) {
      this.formData.warehouseSubsystemInfos.push(value)
      this.$refs.dialog.editFormVisible = false
    },
    // 确认提交
    submitForm(formName) {
      if (!this.formData.warehouseSubsystemInfos && this.formData.warehouseSubsystemInfos.length === 0) return this.$message.warning('请至少添加一条库区数据')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          warehouseUpdateAndAdd(this.formData, () => {
            this.$message.success('成功！')
            this.$router.go(-1)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.lastOne {
  width: 100% !important;
  /deep/ .el-form-item__content {
    width: 100%
  }
}
h3 {
  margin-bottom: 10px;
}
.table-box {
  width: 100%;
  margin-top: 20px;
}
.button-combination {
  text-align: center;
}
.flex-row-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-checkbox {
    margin-bottom: 22px;
  }
}
.goods-allocation {
  /deep/ .el-form-item {
    width: 420px;
  }
}
</style>
